/* @import "tailwindcss/base"; */
@import "/public/fonts/stylesheet.css";
@import "~aos/dist/aos.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper-pagination-bullet{
    background: #850000 !important;
}

.stroke-text{
    --stroke-color:#fff;
    --stroke-width:5px;
    /* color:white; */
    text-shadow: var(--stroke-width)  0 0 var(--stroke-color),
      calc(var(--stroke-width) * -1) 0 0 var(--stroke-color),
      0 var(--stroke-width) 0 var(--stroke-color),
      0 calc(var(--stroke-width) * -1) 0 var(--stroke-color);
}

.smooth-16 {
    text-shadow: calc(var(--stroke-width) * 1) calc(var(--stroke-width) * 0) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * 0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * 0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * 0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * 1) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * 0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * 0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * 0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -1) calc(var(--stroke-width) * 0) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.9239) calc(var(--stroke-width) * -0.3827) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.7071) calc(var(--stroke-width) * -0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * -0.3827) calc(var(--stroke-width) * -0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0) calc(var(--stroke-width) * -1) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.3827) calc(var(--stroke-width) * -0.9239) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.7071) calc(var(--stroke-width) * -0.7071) 0
        var(--stroke-color),
      calc(var(--stroke-width) * 0.9239) calc(var(--stroke-width) * -0.3827) 0
        var(--stroke-color);
}

.disabled-link {
    pointer-events: none;
}

.pack-shake-yellow{
    display: inherit;
    transform-origin: center center;
    animation: pack-shake-yellow 4s infinite ;
}

@keyframes pack-shake-yellow{
    2% { transform: translate(-1px, -5px) rotate(2.5deg); }
    4% { transform: translate(5px, 1px) rotate(-2.5deg); }
    6% { transform: translate(-1px, -5px) rotate(2.5deg); }
    8% { transform: translate(5px, 1px) rotate(-2.5deg); }
    10% { transform: translate(0, 0) rotate(0); }
}

.pack-shake-berries{
    display: inherit;
    transform-origin: center center;
    animation: pack-shake-berries 4s infinite ;
}

.filter-white {
    filter: brightness(0) invert(1);
}

@keyframes pack-shake-berries{
    50% { transform: translate(0, 0) rotate(0); }
    52% { transform: translate(-1px, -5px) rotate(2.5deg); }
    54% { transform: translate(5px, 1px) rotate(-2.5deg); }
    56% { transform: translate(-1px, -5px) rotate(2.5deg); }
    58% { transform: translate(5px, 1px) rotate(-2.5deg); }
    60% { transform: translate(0, 0) rotate(0); }
}

@layer base {

    .mt-nav {
        @apply pt-[71px] md:pt-[85px]
    }

    /* mobile screen */
    .bg_home {
        background-image: url('/img/frontend/banner/home.jpg');
        background-repeat:no-repeat;
        background-size: cover;
        background-position: center;
        /* background-size: 100% 100%; */
        /* position: relative; */
    }

    .bg_about {
        background-image: url('/img/frontend/banner/about.jpg');
        background-repeat:no-repeat;
        background-size: cover;
        background-position: top;
    }

    .bg_promotion {
        background-image: url('/img/frontend/banner/bg_promotion.jpg');
        background-repeat:no-repeat;
        background-size:cover;
        background-position-y: bottom;
    }

    .bg_contact {
        background-image: url('/img/frontend/banner/map.png');
        background-repeat:no-repeat;
        background-size:cover;
    }

    .red_pattern {
        background-image: url('/img/frontend/bg/red_pattern.png');
        background-repeat:no-repeat;
        background-size:cover;
    }

    .bg_sosmed {
        background-image: url('/img/frontend/bg/sosmed.png');
        background-repeat:no-repeat;
        background-size:cover;
        background-position: left;
    }

    .bg_footer {
        background-image: url('/img/frontend/footer/bg_footer.jpg');
        background-repeat:no-repeat;
        background-size:cover;
        background-position: center;
    }

    @screen md {

        .bg_sosmed {
            background-size:fill;
            background-position: center;
        }

        .bg_footer {
            /* background-image: url('/img/frontend/footer/bg_footer.jpg');  */
            /* background-repeat:no-repeat;  */
            background-size:100% 100%;
        }
    }

}

@layer components {

    .nav {
        @apply
                md:h-28
                shadow
                z-20
                relative
    }

    .nav_fixed {
        @apply fixed bg-white
    }

    .nav-trans {
        @apply bg-transparent
    }

    .nav_bar {
        @apply xl:mx-36 h-[72px] md:h-28
    }

    .nav_body {
        @apply flex items-center justify-between mx-5
    }

    .nav_mobile {
        @apply md:hidden inline-flex
    }

    .nav_link_mobile {
        @apply  absolute
                top-0 left-0
                right-0
                z-20
                flex flex-col
                p-2 pb-4 m-2
                space-y-3
                backdrop-blur-sm
                bg-gray-300/50
                rounded
                shadow
    }

    .nav_link_mobile ul {
        @apply self-center text-center space-y-2 font-poppins font-semibold
    }

    .nav_xl {
        @apply md:flex hidden
    }

    .nav_link {
        @apply flex xl:gap-10 gap-4 font-poppins font-semibold
    }

    .nav_link li a {
        @apply uppercase
    }

    .nav_flag-border {
        border: 1px solid trans;
        border-radius: 100%;
        padding: 3px;
    }

    .nav_flag-border:hover {
        border: 1px solid #333;
    }

    .nav_flag-border-active {
        border: 1px solid #000;
        border-radius: 100%;
        padding: 3px
    }
    .nav_flag-border-active:hover {
        border: 1px solid #333;
    }

    .footer {
        @apply flex justify-around gap-4 md:gap-0 text-white container py-20
    }

    .footer_img_product {
        @apply w-52 xl:w-[450px] hidden md:block
    }

    .footer div h3 {
        @apply font-helvetica md:text-xl xl:text-[25.6px]
    }

    .footer_addrs {
        @apply xl:text-[16pt] font-opensans
    }

    .footer_contact {
        @apply my-10 xl:text-[16pt] font-opensans
    }

    .footer_link {
        @apply font-helvetica capitalize -mt-[9px] xl:text-[16pt]
    }

    .footer_link ul li a {
        @apply hover:text-[#e2e2e2] hover:underline
    }

    .slash {
        display: block;
        content: "";
        position: relative;
        top: 50%;
        width: 25px;
        height: 1px;
        background: #000;
        @apply rotate-[120deg]
    }

    .strike {
        display: block;
        text-align: center;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
        @apply absolute rotate-90 top-[30%] right-2
    }

    .strike > span {
        position: relative;
        display: inline-block;
    }

    .strike > span:before {
        content: "";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: #000;
        right: 100%;
        /* margin-right: 15px; */
    }

    .nav_link > li {
        position: relative;
    }

    .nav_active::after {
        position: absolute;
        display:block;
        width: 50%;
        height:4px;
        margin: 0 auto -5px auto;
        background:#850000;
        content:"";
        font-size:0;
        left:0;
        right: 0;
        bottom:0;
    }

    .use_bottom_line:after{
        /* position:absolute; */
        display:block;
        width:60px;
        height:5px;
        margin:auto;
        margin-top: 25px;
        margin-bottom: 25px;
        background:#000;
        content:".";
        font-size:0;
        left:0;
        right:0;
        bottom:0;
    }

    .btm_line_primary::after {
        background: #850000;
    }

    /* home page */
    .banner_home {
        @apply h-[200px] md:h-[700px] shadow-2xl relative;
    }

    .home_banner_body {
        @apply h-full
    }

    .home_banner_text {
        @apply uppercase text-4xl font-dmserif text-[#850000] md:text-6xl xl:text-8xl ml-8 pt-8 md:pt-44
    }

    /* section multiple slide product */
    .section_multiple_products {
        @apply my-10 md:my-28 container flex justify-center relative;
    }

    .wrap_multiple_products {
        @apply container;
    }

    .multiple_products {
        @apply mx-5;
    }

    .products_card {
        @apply w-[280px] mt-[50px] bg-slate-100 shadow-md rounded-3xl px-8 py-5 relative lg:hover:scale-[1.02]
                    2xl:hover:scale-110 hover:bg-red-700
                    cursor-pointer transition ease-in-out duration-300;
    }

    .products_card_image {
        @apply absolute -top-8 left-[50%] -translate-x-[50%];
    }

    .products_card_body {
        @apply mt-10;
    }

    .products_card_body > h2 {
        @apply text-[27px] xl:text-[1.7rem] font-bold text-[#960000] font-poppins group-hover:text-white
    }

    .products-card_footer {
        @apply hidden justify-center mt-2 group-hover:flex;
    }

    .products-card_footer .btn_link {
        @apply text-red-700 px-5 py-1 bg-white rounded-full;
    }

    .sction_product {
        @apply mt-20
    }

    /* .grid_product {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5
    } */
    .grid_product {
        @apply flex flex-wrap justify-center gap-5
    }

    .card_product {
        @apply cursor-pointer transition ease-in-out duration-300 w-[50px] max-w-[50px] md:max-w-[240px] lg:w-[280px] gap-2
    }

    .card_product:hover {
        @apply scale-105 xl:scale-110
    }

    .card_product_img {
        @apply w-[70%] mx-auto -mb-12
    }

    .card_product_body {
        @apply bg-[#F4EDEC] rounded-3xl shadow-md
        group-hover:bg-red-600 group-hover:text-white p-8 pt-16 font-poppins
    }

    .card_product_title {
        @apply font-bold text-3xl text-[#960000]
        group-hover:text-white
    }

    .btn_card_product {
        @apply group-hover:flex justify-center mt-2 hidden
    }

    .btn_card_product a {
        @apply text-red-600 text-center bg-white p-1 w-32 rounded-full
    }

    .section_sosmed {
        @apply h-[300px] xl:h-[900px]
    }

    .sosmed_body {
        @apply mx-10 xl:pt-24
    }

    .sosmed_body h2 {
        @apply font-bold font-montserrat text-[25px] xl:text-[35px] text-[#960000] md:w-[400px]
    }

    .sosmed_icon {
        @apply flex mt-4
    }

    /* .sosmed_icon a {
        @apply w-14 h-14 md:w-20 md:h-20;
    } */


    /* about page */
    .about_header {
        @apply md:h-[500px] lg:h-[700px] py-5 flex flex-wrap items-center justify-center gap-10 container;
    }

    .about_logo_header {
        @apply w-40 xl:w-60
    }

    .about_header_text {
        @apply text-center md:text-left md:ml-20
    }

    .about_header_text > h4 {
        @apply text-lg xl:text-[25px] font-semibold text-white xl:mb-12
    }

    .about_header_text > h1 {
        @apply text-4xl xl:text-[52pt] text-white font-extrabold font-montserrat
    }

    .about_section_1 {
        @apply font-poppins py-10 px-5 mx-auto relative
                text-[18px] container lg:px-60
    }

    .about_gallery {
        @apply flex flex-wrap items-center xl:justify-between justify-center gap-5 my-5 md:my-16;
    }

    .about_gallery > img {
        @apply w-[230px] rounded shadow;
    }

    .about_section_2 {
        @apply red_pattern px-5 py-10 xl:py-20 lg:px-40
    }

    .about_section_2 > div > p {
        @apply text-center text-white text-[9px] md:text-[20px] lg:text-[25px] lg:px-20 2xl:text-[25pt] font-montserrat
    }

    .about_section_2 > div > div {
        @apply flex justify-center mt-10
    }


    /* promotion page */
    .promo_header {
        @apply xl:px-36 px-5 bg_promotion lg:h-[900px] 2xl:h-[1000px]
        /* xl:-mt-[7rem] */
    }

    .promo_head_body {
        @apply flex flex-wrap items-center justify-center pt-10 md:pt-20
    }

    .promo_head_body > h1 {
        @apply font-montserrat font-extrabold md:text-[25pt] lg:text-[38pt] text-center text-[#B52025] stroke-text smooth-16
    }

    .promo_head_img {
        @apply md:flex items-center justify-between mt-5 lg:mt-[50px] md:mb-10
    }

    .promo_img_product {
        @apply lg:max-w-[48rem] 2xl:max-w-[55rem] md:max-w-[25rem]
    }

    .promo_img_ongkir {
        @apply md:h-40 md:w-40 xl:w-[15rem] xl:h-[15rem] hidden md:block
    }

    .section_olshop {
        @apply py-20 bg-gradient-to-r from-[#c0b3a0] to-white
    }

    .olshop_wrap {
        @apply flex flex-wrap items-center justify-center gap-10 lg:px-16 container
    }


    .olshop_wrap > img {
        @apply h-40 w-40 md:hidden
    }

    .olshop_wrap > a {
        @apply bg-white px-10 py-5 rounded-full transition ease-in-out duration-300 hover:scale-110
    }

    /* contact page */
    .has-separator {
        font-size: 1.3rem;
        font-weight: 600;
        color: #999999;
        position: relative;
    }

    .has-separator::before,
    .has-separator::after {
        content: "";
        position: absolute;
        background-color: #442214;
        width: 10%;
        height: 2px;
        top: 50%;
    }

    .has-separator::before {
        left: 15%;
        @apply md:left-[60%]
    }

    .has-separator::after {
        right: 15%;
        @apply md:right-[60%]
    }

    .contact {
        @apply py-10 lg:py-36 bg_contact
    }

    .contact_us {
        @apply mx-auto md:mt-20 my-10 md:my-20 text-center
    }

    .contact_us > h1 {
        @apply font-montserrat text-[30pt] md:text-[38pt] font-extrabold text-[#850000]
    }

    .contact_body {
        @apply flex flex-wrap justify-evenly text-center
    }

    .contact_addss {
        @apply font-poppins text-[#850000]
    }

    .contact_addss > h2 {
        @apply font-bold text-4xl use_bottom_line btm_line_primary
    }

    .contact_sosmed > h2 {
    @apply font-poppins font-bold text-4xl text-[#850000] use_bottom_line btm_line_primary
    }

    .contact_sosmed > div {
        @apply flex items-center justify-center mx-10 md:mx-0
    }

    #stockist {
        @apply container my-20
    }

    .stockis_title {
        @apply flex justify-center my-20
    }

    .stockis_title > h1 {
        @apply font-montserrat text-[38px] lg:text-[38pt] font-extrabold text-[#850000]
    }

    .stockis_card_section {
        @apply flex flex-wrap justify-center gap-8
    }

    .stockis_card {
        @apply max-w-[20rem] bg-white shadow-2xl rounded-[2rem]
    }

    .stockis_card_body {
        @apply font-opensans m-10 my-10
    }

    /* Product Page */
    .product_detail {
        @apply xl:px-40 bg-gradient-to-r from-[#c0b3a0] to-white relative
    }

    .product_border {
        border: 2px solid transparent;
        padding: 10px;
    }

    .product_border:hover {
    border: 2px solid #888;
    }

    /* .product_detail::before {
        display:block;
        position:absolute;
        content:'';
        font-size:0;
        top:-100px;
        left:18%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 100px 0 0 80px;
        border-color: transparent transparent transparent #CDC2B3;
        z-index:1;
    } */

    /* screen 1280px */
    @media only screen and (max-width: 1280px) {

        .about_section_1 {
            @apply text-[16px];
        }

        .about_gallery > img {
            @apply w-[200px]
        }

        .card_product {
            @apply w-[240px]
        }

        .card_product_title {
            @apply text-2xl
        }

        .product_detail {
            @apply md:px-20
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1199px) {

        /* about page */

        .about_section_1, .about_section_2 {
            @apply px-5;
        }

        .about_section_2 > div > p {
            @apply px-0 text-[20px]
        }



        /* promotion page  */
        .promo_img_product {
            @apply max-h-[20rem]
        }

        .promo_header {
            @apply h-[650px]
        }

        .promo_head_body > h1 {
            @apply text-[25pt]
        }
    }
}
