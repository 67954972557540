@font-face {
    font-family: 'DM Serif Display';
    src: url('DMSerifDisplay-Regular.woff2') format('woff2'),
        url('DMSerifDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DM Serif Display';
    src: url('DMSerifDisplay-Regular.woff2') format('woff2'),
        url('DMSerifDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT Std';
    src: url('HelveticaLTStd-Bold.woff2') format('woff2'),
        url('HelveticaLTStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-ExtraBold.woff2') format('woff2'),
        url('Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Medium.woff2') format('woff2'),
        url('Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Bold.woff2') format('woff2'),
        url('Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-SemiBold.woff2') format('woff2'),
        url('Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Italic.woff2') format('woff2'),
        url('Poppins-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto Condensed';
    src: url('RobotoCondensed-Regular.woff2') format('woff2'),
        url('RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Open Sans';
    src: url('OpenSans-Regular.woff2') format('woff2'),
        url('OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}